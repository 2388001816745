import React from "react"
import PropTypes from "prop-types"
import { Container } from "react-bootstrap"

import NavBar from "./navbar"
import Footer from "./footer"
import Header from "./header"
import BackgroundSvg from "../components/background-svg"

// Load scss styles
import "../styles/main.scss"

const Layout = ({
  children,
  pageInfo,
  homepage,
  headerTitle,
  headerDescription,
  headerHideLinks,
}) => {
  return homepage ? (
    <BackgroundSvg>
      <Container fluid className="main-container">
        <NavBar pageInfo={pageInfo} />
        <Header
          title={headerTitle}
          description={headerDescription}
          hideLinks={headerHideLinks}
        />
        <main>{children}</main>
        <Footer />
      </Container>
    </BackgroundSvg>
  ) : (
    <Container fluid className="main-container">
      <BackgroundSvg>
        <NavBar pageInfo={pageInfo} />
        <Header
          title={headerTitle}
          description={headerDescription}
          hideLinks={headerHideLinks}
        />
      </BackgroundSvg>
      <main className="bg-white">{children}</main>
      <Footer className="bg-white" />
    </Container>
  )
}

Layout.defaultProps = {
  children: React.createElement("div"),
  pageInfo: {},
  homepage: false,
  headerTitle: (
    <>
      The Fine Print of{" "}
      <span className="white-space-pre">Ant Money Advisors</span>
    </>
  ),
  headerDescription: ``,
  headerHideLinks: false,
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageInfo: PropTypes.object,
  homepage: PropTypes.bool,
  headerTitle: PropTypes.node,
  headerDescription: PropTypes.string,
  headerHideLinks: PropTypes.bool,
}

export default Layout
