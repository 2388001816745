import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Container } from "react-bootstrap"

const Header = ({ title, description, hideLinks }) => (
  <Container>
    <div className="text-center py-5">
      {title && <h1>{title}</h1>}
      {description && <p>{description}</p>}
      {!hideLinks && (
        <ul className="list-fine-print list-unstyled list-inline">
          <li className="list-inline-item">
            <Link to="/advisory-agreement/">Advisory Agreement</Link>
          </li>
          <li className="list-inline-item">
            <Link to="/wrap-fee-brochure-adv-part-2a/">Wrap Fee Brochure</Link>
          </li>
          <li className="list-inline-item">
            <Link to="/form-crs-adv-part-3/">Form CRS</Link>
          </li>
          <li className="list-inline-item">
            <Link to="/form-crs-conversation-starters/">
              Form CRS Conversation Starters
            </Link>
          </li>
          <li className="list-inline-item">
            <Link to="/legal-disclaimers/">Legal/Disclaimers</Link>
          </li>
        </ul>
      )}
    </div>
  </Container>
)

Header.defaultProps = {
  title: ``,
  description: ``,
  hideLinks: false,
}

Header.propTypes = {
  title: PropTypes.node,
  description: PropTypes.string,
  hideLinks: PropTypes.bool,
}

export default Header
