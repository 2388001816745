import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import { Container, Row, Col } from "react-bootstrap"

const Footer = ({ className }) => (
  <footer className={`footer ${className}`}>
    <Container>
      <Row>
        <Col xs={12} md="4" className="order-2 order-md-0">
          <p className="disclaimer">
            Copyright &copy; {new Date().getFullYear()}{" "}
            <Link to="/">Ant Money Advisors, LLC.</Link> <br />
            All rights reserved worldwide.
          </p>
        </Col>
        <Col xs={12} md="8" className="text-md-right">
          <Link to="/legal-disclaimers/" className="mr-3">
            Legal/Disclaimers
          </Link>
          <Link to="/privacy-policy/" className="mr-3">
            Privacy Policy
          </Link>
          <Link to="/privacy-policy/#ccpa">
            Do Not Sell My Personal Information
          </Link>
        </Col>
      </Row>
    </Container>
  </footer>
)

Footer.defaultProps = {
  className: ``,
}

Footer.propTypes = {
  className: PropTypes.string,
}

export default Footer
