import React from "react"
import PropTypes from "prop-types"

import "./background-svg.scss"

const BackgroundSvg = ({ className, children, ...props }) => (
  <div id="background-svg" className={className} {...props}>
    {children}
  </div>
)

BackgroundSvg.defaultProps = {
  children: React.createElement("div"),
  className: ``,
}

BackgroundSvg.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default BackgroundSvg
