import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Navbar, Nav, Container } from "react-bootstrap"

import MenuSvg from "./Menu.inline.svg"
import CloseBlackSvg from "./Close_Black.inline.svg"

const CustomNavBar = ({ pageInfo }) => (
  <Navbar
    collapseOnSelect
    expand="lg"
    variant="dark"
    id="site-navbar"
    className={pageInfo.pageName && `page-${pageInfo.pageName}`}
  >
    <Container>
      <Link to="/">
        <Navbar.Brand as="span">
          <h1>
            Ant Money <br />
            Advisors, LLC.
          </h1>
        </Navbar.Brand>
      </Link>
      <Navbar.Toggle aria-controls="navbarCollapse" data-toggle="offcanvas">
        <MenuSvg title="Open menu" />
      </Navbar.Toggle>
      <Navbar.Collapse id="navbarCollapse" className="offcanvas-collapse">
        <div className="menu">
          <Navbar.Toggle
            aria-controls="navbarCollapse"
            className="close ml-auto"
            data-toggle="offcanvas"
          >
            <CloseBlackSvg title="Close menu" />
          </Navbar.Toggle>
          <Nav activeKey={pageInfo && pageInfo.pageName}>
            <Link to="/contact-us/">
              <Nav.Link as="span" eventKey="Contact Us">
                Contact Us
              </Nav.Link>
            </Link>
            {/* <Link to="/faq/">
              <Nav.Link as="span" eventKey="FAQ">
                FAQ
              </Nav.Link>
            </Link> */}
          </Nav>
          <Navbar.Text>
            &copy; {new Date().getFullYear()}{" "}
            <Link to="/">AntMoneyAdvisors.com</Link>
          </Navbar.Text>
        </div>
      </Navbar.Collapse>
    </Container>
  </Navbar>
)

CustomNavBar.defaultProps = {
  pageInfo: {},
}

CustomNavBar.propTypes = {
  pageInfo: PropTypes.object,
}

export default CustomNavBar
